import { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { HeaderImg } from './HeaderImg';

const HomeHeader = () => {
  const startRef = useRef(null);
  useEffect(() => {
    startRef.current?.focus();
  }, []);

  return (
    <div className="header">
      <div className="h-content">
        <h1>
          League of Legends <br />
          Coaching
        </h1>
        <h3 className="pb-4">
          Reach your desired LoL rank safely with our Coaching service.
        </h3>
        <Link to="/boosting">
          <button
            ref={startRef}
            type="button"
            className="btn btn-primary mt-5 my-font place-order-btn"
          >
            PLACE ORDER
          </button>
        </Link>
        {/* <div className="d-flex justify-content-center mt-5">
          <TrustpilotBadge
            styleObj={{
              border: 'solid 2px #171630',
              borderRadius: '0.625rem',
              backgroundImage:
                'linear-gradient(rgba(34, 33, 70, 1), rgba(23, 22, 48, 0.5))',
            }}
          />
        </div> */}
      </div>
      <HeaderImg />
    </div>
  );
};

export default HomeHeader;
